import { App, computed, reactive, readonly, ref } from 'vue'
import { setupDevtools } from './devtools'
import { configureAuthorizationHeaderInterceptor } from './interceptors'
import { configureNavigationGuards } from './navigationGuards'
import { ANONYMOUS_USER, AuthOptions, AuthPlugin, RequiredAuthOptions, User } from './types'
import { useLoggedUserStore } from '@/stores/loggedUser'
import { useToast } from 'vue-toastification'

export let authInstance: AuthPlugin | undefined = undefined

function setupAuthPlugin(options: RequiredAuthOptions): AuthPlugin {
  const toast = useToast()
  const router = options.router
  const isAuthenticated = ref(false)
  const accessToken = ref<string>()
  const user = ref<User>({ ...ANONYMOUS_USER })
  const userFullName = computed(() => {
    let fullname = user.value.firstName
    if (user.value.lastName) {
      fullname += ` ${user.value.lastName}`
    }
    return fullname
  })

  // Restore user session from localStorage if available
  const loggedUserStore = useLoggedUserStore()
  if (loggedUserStore.item) {
    user.value = loggedUserStore.item
    if (user.value) {
      accessToken.value = user.value.token
      isAuthenticated.value = true
    } else {
      accessToken.value = undefined
      isAuthenticated.value = false
    }
  }

  const app_env = ref(import.meta.env.VITE_APP_ENV)
  async function login({ username, password }: { username: string; password: string }) {
    try {
      await loggedUserStore.authenticate({ username, password })
      user.value = loggedUserStore.item

      accessToken.value = user.value.token
      isAuthenticated.value = true

      if (undefined == loggedUserStore.currentSource) {
        loggedUserStore.currentSource =
          loggedUserStore.item.source.length > 0 ? loggedUserStore.item.source[0].id : undefined
      }
      const userRole = (user.value.roles && user.value.roles[0]) || 'visitor'

      if (['admin'].includes(userRole) && app_env.value !== 'development') {
        console.log(
          '🚫 Acesso negado. Usuários com papel "admin" ou "analyst" só podem se conectar em ambiente de desenvolvimento.',
        )
        toast.info('Você não pode se conectar a este ambiente com seu papel atual.', { timeout: 3000 })
        localStorage.clear()
        window.open('https://app.portaltelemedicina.com.br/admin/exames', '_blank')
        return false
      } else {
        const redirectTo =
          user.value.roles && user.value.roles.includes('physician')
            ? user.value.physician.listExams
              ? '/radio-report'
              : user.value.physician.onlyReports
                ? '/exams-report'
                : '/no-function'
            : user.value.roles.includes('analyst')
              ? '/analyst'
              : user.value.roles.includes('admin')
                ? '/admin'
                : '/operational'

        toast.success('Autenticado com sucesso', {
          timeout: 3000,
        })

        router.push(redirectTo)
        // router.push(router.currentRoute.value.query.redirectTo?.toString() || redirectTo)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async function logout() {
    user.value = { ...ANONYMOUS_USER }
    isAuthenticated.value = false
    accessToken.value = undefined
    localStorage.clear()
    toast.success('Logout com sucesso', {
      timeout: 3000,
    })

    window.location.href = '/'
    // router.push(options.logoutRedirectRoute)
  }

  /*
   * "reactive" unwraps 'ref's, therefore using the .value is not required.
   * E.g: from "auth.isAuthenticated.value" to "auth.isAuthenticated"
   * but when using destructuring like: { isAuthenticated } = useAuth() the reactivity over isAuthenticated would be lost
   * this is not recommended but in such case use toRefs: { isAuthenticated } = toRefs(useAuth())
   * See: https://v3.vuejs.org/guide/reactivity-fundamentals.html#ref-unwrapping
   * And: https://v3.vuejs.org/guide/reactivity-fundamentals.html#destructuring-reactive-state
   */
  const unWrappedRefs = reactive({
    isAuthenticated,
    accessToken,
    user,
    userFullName,
    login,
    logout,
  })

  return readonly(unWrappedRefs)
}

const defaultOptions = {
  logoutRedirectRoute: '/',
  loginRouteName: 'index',
  autoConfigureNavigationGuards: true,
}

export function createAuth(appOptions: AuthOptions) {
  // Fill default values to options that were not received
  const options: RequiredAuthOptions = { ...defaultOptions, ...appOptions }

  return {
    install: (app: App): void => {
      authInstance = setupAuthPlugin(options)
      app.config.globalProperties.$auth = authInstance

      if (options.autoConfigureNavigationGuards) {
        configureNavigationGuards(options.router, options)
      }

      if (options.axios?.autoAddAuthorizationHeader) {
        configureAuthorizationHeaderInterceptor(options.axios.instance, options.axios.authorizationHeaderPrefix)
      }

      if (import.meta.env.DEV) {
        // until it gets fixed in devtools
        setupDevtools(app, authInstance)
      }
    },
  }
}
